<template>
    <el-dialog title="编辑" :visible="visible" width="60%" @close="close" :close-on-click-modal="false">
        <el-row>
            <el-form ref="form" :model="form" label-width="120px" style="max-height: 50vh;overflow: auto;padding: 20px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="单位">
                            <el-input v-model="form.EJDW" placeholder="请输入" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="名称">
                            <el-input v-model="form.name" placeholder="请输入" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="油">
                            <el-input v-model="form.you" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="盐">
                            <el-input v-model="form.yan" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="糖">
                            <el-input v-model="form.tang" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="菜谱" prop="image">
                            <upload-image2 v-model="form.caipu" title="上传图片" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="form.id">
                        <el-form-item label="评价">
                            <el-input v-model="form.beizhu" type="textarea" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">提 交</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        async formSubmit() {
            const valid = await this.$refs['form'].validate().then(res => res).catch(() => { return false });
            if (!valid) return
            try {
                const resp = await this.$http.post('/diet/task', this.form)
                if (resp.data.status == 'success') {
                    this.$message[resp.data.status](resp.data.msg)
                    this.$emit('close')
                    this.$emit('update')
                } else {
                    this.$alert(resp.data.msg, '出错了', { type: 'error' })
                }
            } catch (error) {
                this.$alert(error.message, '出错了', { type: 'error' })
            }
        },
        close() {
            this.$emit("close")
        },
    },
    mounted() {

    }
};
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
